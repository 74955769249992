<template>
  <BaseLayout :custom-class="{get_app_msm: true}">
    <SubHeader :custom-class="{get_app_msm_sub_header: true}"></SubHeader>
    <div id="bg-container">
      <div class="container">
        <div class="d-flex justify-content-end">
          <div class="context">
            <h3>Search for horses and<br /> service providers... anytime, anywhere</h3>
            <a href="https://apps.apple.com/us/app/id1552998491" target="_blank" class="icon-link"><img src="../assets/img/appstore_button.png" width="180" /></a>
          <a href="https://play.google.com/store/apps/details?id=com.joshyap.manestreetmarket" target="_blank" class="icon-link"><img src="../assets/img/playstore_button.png" width="180" /></a>
          </div>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';

export default {
  components: {BaseLayout, SubHeader},
  mounted() {
    document.title = 'Get the MSM App - Mane Street Market';
    window.scrollTo(0, 0);
  }
}
</script>

<style lang="scss">
.get_app_msm {
  padding-bottom: 0 !important;
}

.get_app_msm_sub_header {
  margin-bottom: 0 !important;
}
</style>

<style lang="scss" scoped>
  #bg-container {
    margin-top: -40px;
    background-image: url("../assets/img/gettheapppage.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 698px;
    position: relative;
  }

  .context {
    position: absolute;
    top: 100px;
    width: 400px;
    color: #fff;
    text-align: center;
  }

  .icon-link {
    display: block;
    margin-top: 1rem;

    &:first-of-type {
      margin-top: 2rem;
    }
  }

  @media screen and (max-width: 480px) {
    #bg-container {
      height: 480px;
    }
    
    .context {
      left: 10%;
      right: 10%;
      width: auto;
    }
  }
</style>